import React from "react";
import Typography from '@mui/material/Typography';

function NotFound(){

    return(
        <>
       <Typography variant="h4">404: page not found!</Typography>
       </>
    );
};
export default NotFound

